import './App.css';
import Header from './components/Header';
import {isMobile,isBrowser} from 'react-device-detect'
import React from 'react';
import {useEffect, useState, useRef} from "react"
import svg from './github-mark/github-mark/a.svg'
import reac from './github-mark/github-mark/reactjs-icon.svg'
import mail from './github-mark/github-mark/email-svgrepo-com.svg'
import tail from './github-mark/github-mark/tailwindcss.svg'
import m from './github-mark/github-mark/Untitled-2.svg'
function App() {
  const refMain = useRef(null)
  const about = useRef(null)
  const contact = useRef(null)
  const cont = useRef(null)
  const greeting = () =>{
    let d = new Date()
    let h = d.getHours()
    return h<3?"Good Evening":h<10?"Good Morning":h<17?"Good Day":"Good Evening"

  }
  const [scroll,setScroll] = useState(true)
  const [scrolla, setScrolla] = useState();
  const [scrollc, setScrollc] = useState()
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(!(window.scrollY > (about.current.offsetTop + about.current.offsetHeight-100)))
      setScrolla(window.scrollY > (about.current.offsetTop + about.current.offsetHeight-100) && !(window.scrollY > (contact.current.offsetTop + contact.current.offsetHeight-400)))
      setScrollc(window.scrollY > (contact.current.offsetTop + contact.current.offsetHeight-400))
      console.log(scrollc)
    });
  }, []);

  const ToMain = () => {
    refMain.current?.scrollIntoView({behavior: 'smooth'});
    
};
const ToAbout = () =>{
  about.current?.scrollIntoView({behavior: 'smooth'})
}
const ToMail = ()=>{
  window.open('mailto:510max.schatz@gmx.at?subject=&body=')

}
const ToContact = () =>{
  cont.current?.scrollIntoView({behavior: 'smooth'})
}

  
  return (
    <div ref={refMain} className=" text-white pb-28">
      <div className=" sticky text-white top-0 z-50 border-b-2 border-white bg-black p-2 flex flex-rows">
        <img onClick={ToMain} className={isBrowser?' ml-20 mr-3':'ml-3'}src={m}/>
        {isBrowser?<div>
        <div  onClick={ToMain} className={` ${scroll ? "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-solid border-white rounded-full  mr-2 ": "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline mr-2"}`}
        >Home</div>            
        <div onClick={ToAbout} className={` ${scrolla ? "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-solid border-white rounded-full  mr-2": "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline mr-2"}`}
        >About</div>            
        <div onClick={ToContact} className={` ${scrollc ? "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-solid border-white rounded-full  mr-2": "inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-gray-600 border-solid rounded-full cursor-pointer select-none hover:text-white hover:border-white focus:shadow-xs focus:no-underline mr-2"}`}
        >Contact</div></div>
      :<div></div>}
      </div>
      <div className={isBrowser?' mt-48 font-bold text-6xl flex justify-center text-center':' mt-48 font-bold text-3xl flex justify-center text-center'}>{greeting()}! I'm Maximilian Schatz.</div>
      <div className={isBrowser?' flex justify-center text-3xl font-semibold mt-3 ml-36 mr-36 text-center':' flex justify-center text-xl font-semibold mt-3 ml-3 mr-3 text-center'}>A 17 year old Student, who's abandoned Web-Development, in order to study Medicine and learn a Variety of different Skills.</div>
      <div className='flex flex-row justify-center mt-5'>


      </div>

      {
        isBrowser?
        <div></div>
        :
        <div className='flex justify-center'>
          <div className='inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-solid border-white rounded-full  mr-2 "'
          onClick={ToAbout}>About</div>
          <div className=' text-white font-light mt-2 pr-3'>or</div>
          <div className='inline-flex items-center justify-center h-10 px-10 py-0 text-xl font-semibold text-center text-gray-200 no-underline align-middle transition-all duration-300 ease-in-out bg-transparent border-2 border-solid border-white rounded-full  mr-2 "'
          onClick={ToContact}>Contact me!</div>
        </div>
      }
      <div ref={about} className=' mt-60'>‎</div>
      <div className={isBrowser?' flex mt-32 ml-36 font-bold text-6xl':' flex mt-32 ml-3 font-bold text-4xl'}>Hey!</div>
      <div className={isBrowser?' font-thin ml-36 mt-4 mr-96 text-xl':' font-thin ml-3 mt-4 mr-3 text-lg'}>I'm Maxi, I started programming in 2018, creating projects of varying quality with various Javascript-Frameworks, Python and Java. While I do not pursue Programming anymore, I do still retain this Knowledge.</div>




      <div className={`${isBrowser? 'ml-36 mr-36 flex flex-row mt-24':'flex justify-center flex-col items-center mt-24'}`}>
      <div className={` bg-white h-80 rounded-3xl text-black mr-10 ${isBrowser ? 'w-1/3':' w-5/6 mb-10 flex justify-center'}`}>
        <div className={isBrowser?' w-full bg-indigo-100 rounded-t-3xl pt-5 p-4 font-medium text-2xl':' w-full bg-indigo-100 rounded-t-3xl rounded-b-3xl pt-5 p-4 font-medium text-2xl'}>Frontend-Development</div>
          <div className=' p-4 font-thin text-lg'>I've used Javascript frameworks like React.js, Next.js and Angular</div>
        </div>
        <div className={` bg-white h-80 rounded-3xl text-black mr-10 ${isBrowser ? 'w-1/3':'w-5/6 mb-10 flex justify-center'}`}>
        <div className={isBrowser?' w-full bg-cyan-100 rounded-t-3xl pt-5 p-4 font-medium text-2xl':' w-full bg-cyan-100 rounded-t-3xl rounded-b-3xl pt-5 p-4 font-medium text-2xl'}>Backend-Development</div>
          <div className=' p-3 font-thin text-lg flex'>Currently my Backend experience is limited to working with Go (utilizing the Gin Framework), Firebase (Authentication, Firestore) and numerous APIs</div>
        </div>
        <div ref={contact} className={` bg-white h-80 rounded-3xl text-black mr-10 ${isBrowser ? 'w-1/3':'w-5/6 flex justify-center'}`}>
        <div className={isBrowser?' w-full bg-teal-100 rounded-t-3xl pt-5 p-4 font-medium text-2xl':' w-full bg-teal-100 rounded-t-3xl rounded-b-3xl pt-5 p-4 font-medium text-2xl'}>‎ UI/Graphic-Design</div>
          <div className=' p-3 font-thin text-lg'>I have worked with Adobe Illustrator and Inkscape and am able to create decent UIs</div>
        </div>
      </div>
{/*  */}
      <div className=' flex justify-center mt-14'>
        <div className=' self-stretch text-black bg-white rounded-3xl'>
          <div className=' text-2xl pb-1 font-medium bg-yellow-100 rounded-t-3xl pt-5 pr-10 pl-10'>
            <div>Current Focus</div>
          </div>
          <div className=' pl-10 pr-10 pb-6 pt-3 font-thin text-lg'>Pre-Med Studies, which includes Biology, Chemistry, Physics, Mathematics and increasing certain cognitive Abilities.<br></br>Next to that, I also focus on acing all my Tests in Highschool.</div>
        </div>

      </div>
      <div className='text-white flex justify-center align-middle items-center mt-36 flex-col'>
        <div className=' font-bold text-4xl'>Want to contact me?</div>
        <a href='https://github.com/xMaxix'>
        <div className=' w-96 mt-6 rounded-lg flex justify-center align-middle h-14 bg-stone-800'>
          <div className=' w-96 mt-6 rounded-lg flex justify-center h-full align-middle  bg-stone-800'><img className='ml-10' src={svg}/>
          <div className=' font-bold text-lg mr-10'>GitHub Profile</div>
        </div>
        </div>

        </a>
        <div onClick={ToMail}>
          
        <div className=' mt-8 w-96 rounded-lg flex justify-center align-middle h-14 bg-stone-700 hover:cursor-pointer'>
          <div className=' w-96 mt-6 rounded-lg flex justify-center h-full align-middle bg-stone-700 '><img className='ml-10 w-10 mr-5 block mb-6' src={mail}/>
          <div className=' font-bold text-lg mr-10'>E-Mail</div></div></div></div>
      </div>
      <div className='grid grid-flow-dense'>

        <div ref={cont} className=' border-t-2 border-white w-full text-white grid content-center items-center align-middle place-content-center grid-flow-col mt-16'>
          <div className=' pt-10 text-xl mr-1'>Made with </div>
          <a href='https://reactjs.org'><img className=' mt-11' src={reac}/></a>
          <a href='https://tailwindcss.com'><img className=' mt-11 ml-1' src={tail}/></a>
        </div>
      </div>
    </div>
  );
}

export default App;
